import { instance } from '../../../../../src/utils';

function getNibcAnbieter(productType) {
  switch (productType) {
    case 'baugeld':
      return 'nibcBaugeldTopAnbieter';
    case 'tagesgeld':
      return 'nibcTagesgeldTopAnbieter';
    case 'festgeld':
      return 'nibcFestgeldTopAnbieter';
    default:
      return '';
  }
}

export default {
  fetchDataForForm({ commit }, payload) {
    const {
      productType,
      zeitraum,
      bankKategorie,
      datePicker,
      berechnung,
      ...rest
    } = payload;
    commit('loading', true);
    return instance(`/api/nibc/${productType}`, { params: rest });
  },
  fetchDataForFirsKfwTable({ commit }, payload) {
    const { productType, dates, ...rest } = payload;
    commit('loading', true);
    const params = Object.assign(rest, { von: dates[0], bis: dates[1] });
    instance(`/api/kfw/${productType}`, { params })
      .then((res) => {
        commit('errorKfw', { [productType]: false });
        if (typeof res.data === 'string') {
          commit('loading', false);
        }
        if (typeof res.data === 'object') {
          commit('setFormData', res.data);
          commit(
            'setDataFirstKfwTable',
            Object.assign(res.data, { productType }, {})
          );
          commit('loading', false);
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('errorKfw', { [productType]: true });
          console.log(e.response);
        }
      });
  },
  fetchDataForSecondKfwTable({ commit }, payload) {
    commit('loading', true);
    instance(`/api/kfw/${payload.productType}/${payload.id}`)
      .then((res) => {
        commit(
          'setDataSecondKfwTable',
          Object.assign(res.data, { productType: payload.productType }, {})
        );
        commit('loading', false);
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('errorKfw', { [payload.productType]: true });
          console.log(e.response);
        }
      });
  },
  sendForm({ commit }, payload) {
    const { productType, berechnung, anlagedauer, ...rest } = payload;
    if (berechnung.berechnungId) {
      const url =
        productType !== 'festgeld'
          ? `/api/nibc/${productType}/${berechnung.berechnungId}`
          : `/api/nibc/${productType}/${berechnung.berechnungId}/${anlagedauer}`;

      instance(`${url}`)
        .then((res) => {
          const topAnbieter = getNibcAnbieter(productType);
          commit(
            'setData',
            Object.assign(res.data, { productType }, { topAnbieter })
          );
          commit('error', { [productType]: false });
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            console.log(e.response);
            commit('loading', false);
            commit('error', { [productType]: true });
          }
        });
    } else {
      commit('error', { [productType]: true });
    }
  },
  fetchFile({ commit }, url) {
    return instance(url, {
      responseType: 'blob',
    });
  },
};
