import html2pdf from 'html2pdf.js';

import { SETTINGS } from './constants';

export const mappedAnlagebetrag = (value, digits) => {
  return (
    Number(value).toLocaleString('de-DE', {
      currency: 'EUR',
      minimumFractionDigits: digits,
    }) +
    ' ' +
    SETTINGS.euroZeichen
  );
};
export const mappedZeitraum = (formParam, date, locale) => {
  if (formParam.zeitraum !== 0) {
    return locale === 'de'
      ? `Zeitraum: ${formParam.zeitraum} Jahr`
      : `Period: ${formParam.zeitraum} Year`;
  } else {
    const period = locale === 'de' ? 'Zeitraum' : 'Period';
    const from = locale === 'de' ? 'von' : 'from';
    const to = locale === 'de' ? 'bis' : 'to';
    const von =
      formParam.zeitraumVon !== null
        ? date(formParam.zeitraumVon).format('DD.MM.YYYY')
        : from;
    const bis =
      formParam.zeitraumBis !== null
        ? date(formParam.zeitraumBis).format('DD.MM.YYYY')
        : to;
    return `${period}: ${von}  -  ${bis}`;
  }
};

export const mappedKunderkreis = (value, locale) => {
  const val1 =
    locale === 'de' ? 'Neu- und Bestandskunden' : 'New and existing customers';
  const val2 =
    locale === 'de' ? 'Nur Neukundenangebote' : 'New customer offers only';
  const val3 =
    locale === 'de'
      ? 'Nur Bestandskundenangebote'
      : 'Only existing customer offers';
  if (value === 0) {
    return val1;
  } else if (value === 1) {
    return val2;
  } else if (value === 2) {
    return val3;
  }
};

export const mappedAnlagedauer = (value, locale) => {
  if (locale === 'de') {
    switch (value) {
      case 1:
        return '1 Monat';
      case 3:
        return '3 Monate';
      case 6:
        return '6 Monate';
      case 9:
        return '9 Monate';
      case 12:
        return '12 Monate';
      case 18:
        return '18 Monate';
      case 24:
        return '2 Jahre';
      case 36:
        return '3 Jahre';
      case 48:
        return '4 Jahre';
      case 60:
        return '5 Jahre';
      case 72:
        return '6 Jahre';
      case 84:
        return '7 Jahre';
      case 96:
        return '8 Jahre';
      case 108:
        return '9 Jahre';
      case 120:
        return '10 Jahre';
    }
  } else {
    switch (value) {
      case 1:
        return '1 Month';
      case 3:
        return '3 Month';
      case 6:
        return '6 Month';
      case 9:
        return '9 Month';
      case 12:
        return '12 Month';
      case 18:
        return '18 Month';
      case 24:
        return '2 Year';
      case 36:
        return '3 Year';
      case 48:
        return '4 Year';
      case 60:
        return '5 Year';
      case 72:
        return '6 Year';
      case 84:
        return '7 Year';
      case 96:
        return '8 Year';
      case 108:
        return '9 Year';
      case 120:
        return '10 Year';
    }
  }
};
export async function createPDF(ref, fileName) {
  ref.style.fontFamily = 'Recursive';
  ref.style.color = '#2c3e50';
  const opt = {
    margin: [0, 0, 0, 0],
    filename: `${fileName}`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      useCORS: true,
      imageTimeout: 0,
    },
    pagebreak: {
      mode: ['avoid-all', 'css', 'legacy'],
    },
    jsPDF: {
      unit: 'pt',
      format: [600, 1200],
      orientation: 'landscape',
    },
  };
  return html2pdf()
    .from(ref)
    .set(opt)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
      }
    })
    .save();
}

export function numberWithCommas(value) {
  if (value === null || value === undefined) {
    return 0;
  }
  const str = value.toLocaleString('de-DE');
  if (str.length > 5) return str;
  const parts = str.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}

export const numberAfterPoint = (num) =>
  num.toString().includes('.') ? num.toString().split('.').pop().length : 0;

export function ucFirst(str) {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}

export const checkedCheckBoxes = (chartData, formParam) => {
  const zins1 = chartData.data.filter((item) => {
    return typeof item?.zins1 === 'number' && !isNaN(item?.zins1);
  });
  const zins2 = chartData.data.filter(
    (item) => typeof item?.zins2 === 'number' && !isNaN(item?.zins2)
  );
  const zins3 = chartData.data.filter(
    (item) => typeof item?.zins3 === 'number' && !isNaN(item?.zins3)
  );
  const zins4 = chartData.data.filter(
    (item) => typeof item?.zins4 === 'number' && !isNaN(item?.zins4)
  );
  const zins5 = chartData.data.filter(
    (item) => typeof item?.zins5 === 'number' && !isNaN(item?.zins5)
  );

  return {
    zins1: !!zins1.length && !!formParam?.bankname1,
    zins2: !!zins2.length && !!formParam?.bankname2,
    zins3: !!zins3.length && !!formParam?.bankname3,
    zins4: !!zins4.length && !!formParam?.bankname4,
    zins5: !!zins5.length && !!formParam?.bankname5,
  };
};

export const wettbewerbTableHeaders = (bank1, bank2, bank3, bank4, bank5) => {
  return [
    {
      value: 'datum',
      text: 'Datum',
    },
    {
      value: 'min',
      text: 'Min.Zins',
    },
    {
      value: 'max',
      text: 'Max.Zins',
    },
    {
      value: 'mittelwert',
      text: 'Mittelwert',
    },
    {
      value: 'zins1',
      text: bank1,
    },
    {
      value: 'zins2',
      text: bank2,
    },
    {
      value: 'zins3',
      text: bank3,
    },
    {
      value: 'zins4',
      text: bank4,
    },
    {
      value: 'zins5',
      text: bank5,
    },
  ].filter((item) => item.text);
};

export function payloadForUpdateReport(report, reportType, host) {
  const arr = [];
  const o = {};
  const { zeitraumVon, zeitraumBis, zeitraum, chartZinsValue, ...rest } =
    report.formParam;
  Object.assign(o, rest);
  if (zeitraum === 0) {
    o.zeitraumVon = new Date(`${zeitraumVon}`).toISOString();
    o.zeitraumBis = new Date(`${zeitraumBis}`).toISOString();
  }
  if (zeitraum !== 0) {
    o.zeitraum = zeitraum;
    o.zeitraumVon = '';
    o.zeitraumBis = '';
  }
  Object.keys(o).forEach((key) => {
    if (key === 'vermittlerangebote') {
      arr.push(`${key}=${o[key].value}`);
    }
    if (o[key] && key !== 'vermittlerangebote') {
      arr.push(`${key}=${encodeURIComponent(o[key])}`);
    }
  });
  const str = arr.join('&');
  return Object.assign({
    config: {
      chartParams: report.chartParam,
      url: str,
      host,
    },
    slug: report.slug,
    reportType,
  });
}

export const fileName = (title) => {
  if (title) {
    return title
      .replace(/[^a-zA-ZßäüöÄÖÜ0-9,.\s]/gi, ' ')
      .replace(/^\s+|\s+$/g, '')
      .replace(/\s+/g, '_');
  }
  return 'file';
};
export const headersForCSV = (headers) => {
  return headers
    .map((header) => {
      return { [header.value]: { title: header.text } };
    })
    .reduce((result, object) => Object.assign(result, object), {});
};
export const rowsForCSV = (rows, headersCSV, date) => {
  return rows.map((row) => {
    const o = {};
    Object.keys(headersCSV).forEach((key) => {
      if (key === 'datum') {
        Object.assign(o, {
          [key]: date(row[key]).format('DD.MM.YYYY'),
        });
      } else {
        const value = row[key] || 0;
        Object.assign(o, {
          [key]: `${Number(value).toLocaleString('de-DE', {
            minimumFractionDigits: 2,
          })}%`,
        });
      }
    });
    return o;
  });
};
