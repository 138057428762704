export default {
  getDateNowTagesgeld(state) {
    return state.dateNowTagesgeld;
  },
  getDateNowFestgeld(state) {
    return state.dateNowFestgeld;
  },
  getDateNowBaugeld(state) {
    return state.dateNowBaugeld;
  },
};
