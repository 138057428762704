import mutations from './mutations';
import getters from './getters';

const state = {
  dateNowTagesgeld: '',
  dateNowFestgeld: '',
  dateNowBaugeld: '',
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
