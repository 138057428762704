import { instance } from '@/utils';

const modifySections = (arr) => {
  return arr.map((section) => {
    if (section.step_id === 'banken_step') {
      return {
        ...section,
        content: section.content.map((item) => {
          return {
            ...item,
            elements: item.elements.map((element) => {
              return {
                ...element,
                values: element.values.map((value) => {
                  return {
                    ...value,
                    name:
                      value.name.charAt(value.name.length - 1) === '*'
                        ? `${value.name.slice(
                            0,
                            value.name.length - 1
                          )} (Business)`
                        : value.name,
                  };
                }),
              };
            }),
          };
        }),
      };
    }
    return section;
  });
};
export default {
  fetchWizardConfigFestgeld({ commit }) {
    return instance
      .get('/api/wizard-festgeld/')
      .then((res) => {
        if ((res?.data?.sections || []).length) {
          commit(
            'fetchWizardConfigFestgeldSuccess',
            modifySections(res.data.sections)
          );
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchWizardConfigTagesgeld({ commit }) {
    instance
      .get('/api/wizard-tagesgeld/')
      .then((res) => {
        if ((res?.data?.sections || []).length) {
          commit(
            'fetchWizardConfigTagesgeldSuccess',
            modifySections(res.data.sections)
          );
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchWizardConfigBaugeld() {
    return instance.get('/api/wizard-baugeld/');
  },
};
