import { getField } from 'vuex-map-fields';

export default {
  getField,
  getFormKfwTagesgeld(state) {
    return state.formKfwTagesgeld;
  },
  getFormKfwBaugeld(state) {
    return state.formKfwBaugeld;
  },
  getKfwFirstTable(state) {
    return state.kfwFirstTable;
  },
  getKfwSecondTable(state) {
    return state.kfwSecondTable;
  },
  getFieldValue: (state) => (path) => {
    let paths = path.split('.');
    if (paths.length === 2) {
      return state[paths[0]][paths[1]];
    } else {
      return state[paths[0]];
    }
  },
  getDataTable(state) {
    return state.dataTable;
  },
  getLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  },
  getFormParamTagesgeld(state) {
    return state.formParamTagesgeld;
  },
  getFormParamFestgeld(state) {
    return state.formParamFestgeld;
  },
  getFormParamBaugeld(state) {
    return state.formParamBaugeld;
  },
  getErrorKfw(state) {
    return state.errorKfw;
  },
};
