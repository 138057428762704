import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export function defaultState() {
  return {
    error: false,
    loading: false,
    baseChartData: [],
    specialChartData: [],
  };
}
const state = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
