import Vue from 'vue';

import { SETTINGS } from './utils/constants';

Vue.filter('anlagebetrag', (value, digits) => {
  return (
    value.toLocaleString('de-DE', {
      currency: 'EUR',
      minimumFractionDigits: digits,
    }) +
    ' ' +
    SETTINGS.euroZeichen
  );
});
Vue.filter('prozent', (value, digits) => {
  return (
    value.toLocaleString('de-DE', {
      minimumFractionDigits: digits,
    }) +
    ' ' +
    SETTINGS.prozentZeichen
  );
});
Vue.filter('zeitraumJahre', (value) => {
  if (value === 0) {
    return 'Von - bis';
  } else if (value === 1) {
    return `${value} Jahr`;
  } else {
    return `${value} Jahre`;
  }
});
Vue.filter('periodYears', (value) => {
  if (value === 0) {
    return 'From - to';
  } else if (value === 1) {
    return `${value} Year`;
  } else {
    return `${value} Year`;
  }
});
Vue.filter('kundenkreis', (value) => {
  if (value === 0) {
    return 'Neu- und Bestandskunden';
  } else if (value === 1) {
    return 'nur Neukunden';
  } else if (value === 2) {
    return 'nur Bestandskunden';
  }
});
Vue.filter('clientele', (value) => {
  if (value === 0) {
    return 'New and existing customer offers';
  } else if (value === 1) {
    return 'New customer offers only';
  } else if (value === 2) {
    return 'Only existing customer offers';
  }
});
Vue.filter('vermittlerangebote', (value) => {
  if (value === 0) {
    return 'Nein';
  } else if (value === 1) {
    return 'Ja';
  } else if (value === 2) {
    return 'Nur Vermittlerangebote';
  }
});
Vue.filter('datum', (d) => {
  return d.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
});
