export default {
  getTabs(state) {
    return state.tabs;
  },
  getTable(state) {
    return state.table;
  },
  getError(state) {
    return state.error;
  },
  getLoading(state) {
    return state.loading;
  },
};
