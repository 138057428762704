import axios from 'axios';
import { URLS } from '../../utils/constants';

const state = {
  anbieterMap: {},
  anbieterMapState: false,
};

const getters = {
  getCount(state) {
    return state.anbieterMap.size;
  },
  getAnbieter(state) {
    return state.anbieterMap;
  },
};

const actions = {
  loadAnbieter({ commit }, auswahl) {
    axios
      .get(`${URLS[4]}anbieter?auswahl=${auswahl}`)
      .then((response) => {
        commit('setAnbieter', { auswahl: auswahl, anbieter: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadAnbieterAsync({ commit }, auswahl) {
    await axios
      .get(`${URLS[4]}anbieter?auswahl=${auswahl}`)
      .then((response) => {
        commit('setAnbieter', { auswahl: auswahl, anbieter: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadAnbieterFestGeld({ commit }, auswahl) {
    await axios
      .get(`${URLS[5]}anbieter?auswahl=${auswahl}`)
      .then((response) => {
        commit('setAnbieter', { auswahl: auswahl, anbieter: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadAnbieterHypotheken({ commit }, auswahl) {
    await axios
      .get(`${URLS[6]}anbieter?auswahl=${auswahl}`)
      .then((response) => {
        commit('setAnbieter', { auswahl: auswahl, anbieter: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

const mutations = {
  setAnbieter(state, { auswahl, anbieter }) {
    state.anbieterMap = Object.assign({}, state.anbieterMap, {
      [auswahl]: anbieter || [],
    });
  },
  setAnbieterMapState(state, value) {
    state.anbieterMapState = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
