/*eslint-disable*/
import { updateField } from 'vuex-map-fields';
import { ucFirst } from '../../../utils/helpers';
import { defaultState } from './index';

function getHeadersKfwTable(payload) {
  const index = Object.keys(payload.kfwZinsen.data[0]).findIndex(
    (item) => item === 'berechnungId'
  );
  const arr = Object.keys(payload.kfwZinsen.data[0]);
  arr.splice(index, 1);
  arr.push('berechnungId');
  return arr
    .map((item) => {
      if (item === 'berechnungId') {
        return {
          text: 'Top',
          value: item,
          sortable: false,
        };
      }
      return {
        text: ucFirst(item),
        value: item,
      };
    })
    .filter(
      (item) =>
        item.value !== 'effektivzinsMittelwert' &&
        item.value !== 'sollzinsMittelwert'
    );
}
function getHeadersValue(payload, topAnbieter) {
  if (topAnbieter === 'kfwTopAnbieter') {
    return Object.keys(payload[topAnbieter].data[0]).map((item) => {
      if (item === 'regionaleBank') {
        return {
          text: ucFirst(item), //.replace(/(?<!^)([A-Z])/, ' $1'),
          value: item,
          sortable: false,
        };
      }
      if (item === 'anbietername') {
        return {
          text: 'Top-30-Anbieter',
          value: item,
        };
      }
      return {
        text: ucFirst(item),
        value: item,
      };
    });
  } else {
    return Object.keys(payload[topAnbieter].data[0]).map((item) => {
      if (item === 'regionaleBank') {
        return {
          text: ucFirst(item), //.replace(/(?<!^)([A-Z])/, ' $1'),
          value: item,
          sortable: false,
        };
      }
      return {
        text: ucFirst(item),
        value: item,
      };
    });
  }
}
function getZinsen(productType) {
  switch (productType) {
    case 'baugeld':
      return 'nibcBaugeldZinsen';
    case 'tagesgeld':
      return 'nibcTagesgeldZinsen';
    case 'festgeld':
      return 'nibcFestgeldZinsen';
    default:
      return '';
  }
}

function getBerehnungData(state, payload, maxDate, productType) {
  if (productType === 'baugeld') {
    if (Object.keys(state.formParamBaugeld.berechnung).length) {
      return payload.nibcBaugeldZinsen.data.find(
        (item) =>
          new Date(item.datum).getTime() ===
          new Date(state.formParamBaugeld.berechnung.datum).getTime()
      );
    } else {
      return payload.nibcBaugeldZinsen.data.find(
        (item) => new Date(item.datum).getTime() === maxDate
      );
    }
  }
  if (productType === 'tagesgeld') {
    if (Object.keys(state.formParamTagesgeld.berechnung).length) {
      return payload.nibcTagesgeldZinsen.data.find(
        (item) =>
          new Date(item.datum).getTime() ===
          new Date(state.formParamTagesgeld.berechnung.datum).getTime()
      );
    } else {
      return payload.nibcTagesgeldZinsen.data.find(
        (item) => new Date(item.datum).getTime() === maxDate
      );
    }
  }
  if (productType === 'festgeld') {
    if (Object.keys(state.formParamFestgeld.berechnung).length) {
      return payload.nibcFestgeldZinsen.data.find(
        (item) =>
          new Date(item.datum).getTime() ===
          new Date(state.formParamFestgeld.berechnung.datum).getTime()
      );
    } else {
      return payload.nibcFestgeldZinsen.data.find(
        (item) => new Date(item.datum).getTime() === maxDate
      );
    }
  }
}

export default {
  updateField,
  setBerechnung(state, payload) {
    const { productType, ...rest } = payload;
    if (productType === 'baugeld') {
      state.formParamBaugeld = { ...state.formParamBaugeld, berechnung: rest };
    }
    if (productType === 'tagesgeld') {
      state.formParamTagesgeld = {
        ...state.formParamTagesgeld,
        berechnung: rest,
      };
    }
    if (productType === 'festgeld') {
      state.formParamFestgeld = {
        ...state.formParamFestgeld,
        berechnung: rest,
      };
    }
  },
  error(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state.error[key] = val;
  },
  errorKfw(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state.errorKfw[key] = val;
  },
  setDataForm(state, payload) {
    const { productType, ...rest } = payload;
    const datePicker = {};
    let zinsen = getZinsen(productType);
    if (rest[zinsen]?.data.length) {
      datePicker.dates = rest[zinsen].data.map((item) =>
        new Date(item.datum).getTime()
      );
      const max = Math.max(...datePicker.dates); //for first render
      const berechnung = getBerehnungData(state, rest, max, productType);
      datePicker.originalArr = rest[zinsen].data;
      if (productType === 'baugeld') {
        state.formParamBaugeld = {
          ...state.formParamBaugeld,
          ...{ datePicker },
          berechnung,
          zeitraum: berechnung.datum,
        };
      }
      if (productType === 'tagesgeld') {
        state.formParamTagesgeld = {
          ...state.formParamTagesgeld,
          ...{ datePicker },
          berechnung,
          zeitraum: berechnung.datum,
        };
      }
      if (productType === 'festgeld') {
        state.formParamFestgeld = {
          ...state.formParamFestgeld,
          ...{ datePicker },
          berechnung,
          zeitraum: berechnung.datum,
        };
      }
    } else {
      datePicker.dates = [];
      datePicker.originalArr = [];
      if (productType === 'baugeld') {
        state.formParamBaugeld = {
          ...state.formParamBaugeld,
          ...{ datePicker },
          zeitraum: null,
          berechnung: {},
        };
      }
      if (productType === 'tagesgeld') {
        state.formParamTagesgeld = {
          ...state.formParamTagesgeld,
          ...{ datePicker },
          zeitraum: null,
          berechnung: {},
        };
      }
      if (productType === 'festgeld') {
        state.formParamFestgeld = {
          ...state.formParamFestgeld,
          ...{ datePicker },
          zeitraum: null,
          berechnung: {},
        };
      }
      state.dataTable[productType] = { headers: [], rows: [] };
    }
  },
  setDataSecondKfwTable(state, payload) {
    const { productType, ...rest } = payload;
    const headers = rest.kfwTopAnbieter?.data?.length
      ? getHeadersValue(rest, 'kfwTopAnbieter')
      : [];
    const rows = rest.kfwTopAnbieter?.data?.length
      ? rest.kfwTopAnbieter.data.sort((a, b) => {
          return a.effektivzins > b.effektivzins ? -1 : 1;
        })
      : [];
    switch (productType) {
      case 'baugeld':
        return (state.kfwSecondTable.baugeld = {
          headers: headers,
          rows: rows,
        });
      case 'tagesgeld':
        return (state.kfwSecondTable.tagesgeld = {
          headers: headers,
          rows: rows,
        });
      case 'festgeld':
        return (state.kfwSecondTable.festgeld = {
          headers: headers,
          rows: rows,
        });
      default:
        return state.kfwSecondTable.default;
    }
  },
  setFormData(state, payload) {
    const dates = payload.kfwZinsen.data.map((item) => {
      return new Date(item.datum).getTime();
    });
    const minDate = payload.kfwZinsen.data.find((item) => {
      return new Date(item.datum).getTime() === Math.min(...dates);
    });
    const maxDate = payload.kfwZinsen.data.find((item) => {
      return new Date(item.datum).getTime() === Math.max(...dates);
    });
    state.formKfwBaugeld = {
      ...state.formKfwBaugeld,
      dates: [minDate.datum.split('T')[0], maxDate.datum.split('T')[0]],
    };
  },
  setDataFirstKfwTable(state, payload) {
    const { productType, ...rest } = payload;
    const headers = rest.kfwZinsen?.data?.length
      ? getHeadersKfwTable(rest)
      : [];

    const rows = rest.kfwZinsen?.data?.length ? rest.kfwZinsen.data : [];
    switch (productType) {
      case 'baugeld':
        return (state.kfwFirstTable.baugeld = { headers: headers, rows: rows });
      case 'tagesgeld':
        return (state.kfwFirstTable.tagesgeld = {
          headers: headers,
          rows: rows,
        });
      case 'festgeld':
        return (state.kfwFirstTable.festgeld = {
          headers: headers,
          rows: rows,
        });
      default:
        return state.kfwFirstTable.default;
    }
  },
  setData(state, payload) {
    const { productType, topAnbieter, ...rest } = payload;
    const headers = rest[topAnbieter]?.data?.[0]
      ? getHeadersValue(rest, topAnbieter)
      : [];
    const rows = rest[topAnbieter]?.data?.length ? rest[topAnbieter].data : [];
    switch (productType) {
      case 'baugeld':
        return (state.dataTable.baugeld = { headers: headers, rows: rows });
      case 'tagesgeld':
        return (state.dataTable.tagesgeld = { headers: headers, rows: rows });
      case 'festgeld':
        return (state.dataTable.festgeld = { headers: headers, rows: rows });
      default:
        return state.dataTable.default;
    }
  },

  forceUpdateSpecialabonenten(state, payload) {
    state.forceUpdateSpecialabonenten = payload;
  },
  setFieldValue(state, { path, value }) {
    const paths = path.split('.');
    if (paths.length === 2) {
      state[paths[0]][paths[1]] = value;
    } else {
      state[paths[0]] = value;
    }
  },
  loading(state, payload) {
    state.loading = payload;
  },
  resetFormTagesgeld(state) {
    state.formParamTagesgeld = {
      productType: 'tagesgeld',
      anlagebetrag: 5000,
      zeitraum: null,
      datePicker: {
        dates: [],
      },
      berechnung: {},
    };
  },
  resetFormFestgeld(state) {
    state.formParamFestgeld = {
      productType: 'festgeld',
      zeitraum: null,
      anlagedauer: 3,
      datePicker: {
        dates: [],
      },
      berechnung: {},
    };
  },
  resetTable(state, payload) {
    state.dataTable[payload] = { headers: [], rows: [] };
  },
  resetFormBaugeld(state) {
    const result = {
      productType: 'baugeld',
      zeitraum: null,
      sollzinsbindung: 5,
      beleihung: 55,
      nutzung: 1,
      bankKategorie: 0,
      datePicker: {
        dates: [],
      },
      berechnung: {},
    };
    state.formParamBaugeld = Object.assign(state.formParamBaugeld, result, {});
  },
  resetKfwTables(state, key) {
    state.kfwFirstTable[key] = {
      headers: [],
      rows: [],
    };
    state.kfwSecondTable[key] = {
      headers: [],
      rows: [],
    };
  },
  resetKfwSecondTable(state, key) {
    state.kfwSecondTable[key] = {
      headers: [],
      rows: [],
    };
  },
  resetKfwFormBaugeld(state) {
    state.formKfwBaugeld = {
      productType: 'baugeld',
      dates: [],
      sollzinsbindung: 0,
    };
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
  },
};
