export default {
  getReports(state) {
    return state.allReports;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  uploadedReports(state) {
    return state.uploadedReports;
  },
  getBankenProfile(state) {
    return state.bankenProfile;
  },
  getSnackbarData(state) {
    return state.snackbarData;
  },
  getSingleReport(state) {
    return state.singleReport;
  },
  getAvailableDates(state) {
    return state.availableDates;
  },
  getLoadingByDate(state) {
    return state.isLoadingByDate;
  },
  getBankDetails(state) {
    return state.bankDetails;
  },
};
