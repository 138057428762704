export default {
  setDateNowFromTagesgeld(state, payload) {
    state.dateNowTagesgeld = payload;
  },
  setDateNowFromFestgeld(state, payload) {
    state.dateNowFestgeld = payload;
  },
  setDateNowFromBaugeld(state, payload) {
    state.dateNowBaugeld = payload;
  },
};
