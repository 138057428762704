import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  user: null,
  isaAuthorizationExpired: false,
  isAuthorized: false,
  permissions: ['rest'],
  error: false,
  loading: false,
  snackbarData: {
    show: false,
    text: '',
    timeout: 2000,
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
