<template>
  <v-navigation-drawer
    v-model="valueDrawer"
    :mini-variant="valueMini"
    :width="360"
    permanent
    app
    clipped
  >
    <v-list class="navigation-container">
      <div v-for="(item, index) in config" :key="index">
        <div v-if="!item.isPanel && validationNotPanels(item.permission)">
          <v-list-item
            :to="item.to"
            link
            active-class="panel-active"
            v-if="!item.tooltip"
          >
            <v-list-item-icon
              ><v-icon color="#fff">{{ item.icon }}</v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title class="list-title default-font">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :to="item.to" link active-class="panel-active">
            <v-tooltip right :color="color" :nudge-right="item.nudge[mini]">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="v-list-item pl-0">
                  <v-list-item-icon
                    ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="list-title default-font">
                      <span
                        class="default-font"
                        v-if="item.title === 'sidebar.menu.providerProfiles'"
                        >{{ $t(item.title) }} <sup>BETA</sup></span
                      >
                      <span v-else class="default-font">{{
                        $t(item.title)
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </div>
              </template>
              <span class="tooltip-info inter">{{ $t(item.tooltipText) }}</span>
            </v-tooltip>
          </v-list-item>
        </div>
        <div class="panel-container" v-if="item.isPanel">
          <v-expansion-panels
            class="menu-panel"
            :value="panel"
            @change="handleChangePanel($event)"
          >
            <v-expansion-panel
              active-class="panel-active"
              class="panel-not-active"
              v-for="panelIndex in [1, 2, 5, 6]"
              :key="panelIndex"
            >
              <v-expansion-panel-header
                v-if="panelIndex === index && validate(item)"
              >
                <div v-if="item.tooltip">
                  <v-tooltip
                    right
                    :color="color"
                    :nudge-right="item.nudge[mini]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="item.className">
                        <v-list-item-icon>
                          <v-icon color="#fff">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="list-title default-font">{{
                            $t(item.title)
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                    <span class="tooltip-info inter">{{
                      $t(item.tooltipText)
                    }}</span>
                  </v-tooltip>
                </div>
                <div :class="item.className" v-else>
                  <v-list-item-icon>
                    <v-icon color="#fff">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="list-title default-font">{{
                      $t(item.title)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </div>
              </v-expansion-panel-header>
              <div class="panel-content" v-if="panelIndex === index">
                <v-expansion-panel-content
                  v-for="(children, index) in item.items"
                  :key="index"
                >
                  <v-list-item
                    :to="children.to"
                    link
                    active-class="active-link"
                    v-if="permissions.includes(children.permission)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="list-title default-font">{{
                        $t(children.title)
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </div>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-list>
    <template v-slot:append>
      <div class="toggle-menu-icon" @click="handleChangeDrawer">
        <v-icon :color="blackColor" :class="{ 'rotate-icon': mini }"
          >mdi-arrow-left-drop-circle</v-icon
        >
        <span class="base-normal-color">{{
          $t('general.actionBtn.zoom')
        }}</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations, MutationMethod } from 'vuex';

import TopBar from '@/components/TopBar/TopBar.vue';
import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  NAVIGATION_CONFIG,
} from '@/utils/constants';

@Component({
  components: { TopBar },
  computed: {
    ...mapGetters({
      error: 'reports/error',
      wizardError: 'wizard/error',
      maxColumns: 'wizard/maxColumns',
      updateExistingReport: 'reports/updateExistingReport',
      permissions: 'auth/getPermissions',
    }),
  },
  methods: {
    ...mapMutations({
      resetError: 'reports/resetError',
      resetWizardError: 'wizard/resetError',
      clearSingleReport: 'reports/clearSingleReport',
      clearDatenaboData: 'datenabo/clearDatenaboData',
    }),
  },
})
export default class Navigation extends Vue {
  @Prop() drawer!: Boolean;
  @Prop() mini!: Boolean;

  color: String = BASE_COLOR;
  blackColor = BASE_BLACK_COLOR;
  config: Object = NAVIGATION_CONFIG;
  panel = null;
  error!: Boolean;
  wizardError!: Boolean;
  clearSingleReport!: MutationMethod;
  resetError!: MutationMethod;
  resetWizardError!: MutationMethod;
  permissions!: Array<string>;

  @Watch('$route', { immediate: true }) set() {
    if (
      (this.$router as any).history.current.path === '/' ||
      (this.$router as any).history.current.path === '/chart-generator' ||
      (this.$router as any).history.current.path === '/banking-profile'
    ) {
      this.panel = null;
    }
    if ((this.$router as any).history.current.meta.hasOwnProperty('panel')) {
      if (!this.drawer) {
        this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
      }
      if (!this.panel) {
        this.panel = this.currentPanel();
      }
    }
    if (this.error && !Object.keys(this.$router.currentRoute.params).length) {
      this.resetError();
    }
    if (
      this.wizardError &&
      !Object.keys(this.$router.currentRoute.params).length
    ) {
      this.resetWizardError();
    }
    if (
      !Object.keys(this.$router.currentRoute.params).length &&
      !this.$router.currentRoute.path.includes('banking-profile')
    ) {
      this.clearSingleReport();
    }
  }
  @Watch('drawer') changePanel() {
    if (!this.drawer && this.panel !== null) {
      this.panel = null;
    }
    if (
      this.drawer &&
      this.panel === null &&
      (this.$router as any).history.current.meta.hasOwnProperty('panel')
    ) {
      this.panel = this.currentPanel();
    }
  }

  validationNotPanels(permission) {
    if (Array.isArray(permission)) {
      const search = this.permissions.filter((item) =>
        permission.includes(item)
      );
      return search.length >= 3;
    }
    return this.permissions.includes(permission);
  }
  validate(panel) {
    const allPermissions = panel.items
      .map((item) => item.permission)
      .filter((i) => i);
    if (allPermissions.length) {
      return allPermissions.some((it) => this.permissions.includes(it));
    } else {
      return this.permissions.includes(panel.permission);
    }
  }
  handleChangeDrawer() {
    this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
  }

  currentPanel() {
    const permissions = JSON.parse(localStorage.getItem('permissions') || '');
    const arr = (this.config as any).map((item) => {
      const allPermissions = item.items
        .map((item) => item?.permission)
        .filter((i) => i);
      if (allPermissions.length) {
        return allPermissions.some((it) => permissions.includes(it)) && item;
      }
      return permissions.includes(item.permission) && item;
    });
    const indexes = {
      1: 0,
      2: 1,
      5: 2,
      6: 3,
    };
    return indexes[
      arr.findIndex(
        (item) =>
          item?.label === (this.$router as any).history.current.meta.panel
      )
    ];
  }
  get valueMini() {
    return this.mini;
  }
  set valueMini(val) {}
  get valueDrawer() {
    return this.drawer;
  }
  set valueDrawer(val) {}
  handleChangePanel(e) {
    if (typeof e === 'undefined') {
      this.panel = null;
    } else {
      this.panel = e;
    }
    if (!this.drawer && this.panel !== null) {
      this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/function';

.active {
  align-items: center !important;
  flex-direction: row !important;
  padding-left: 20px !important;
  & > img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
  & > div > img:first-child {
    width: 60px;
    height: 50px;
  }
  & > div > img:last-child {
    padding-top: 10px;
    margin-left: 30px;
  }
}
.toggle-menu-icon {
  display: flex;
  padding: 16px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  & > i {
    margin-right: 32px;
  }
  & > span {
    color: $base-color;
  }
}

.panel-content {
  & > .v-expansion-panel-content > div > a {
    &:before {
      border-radius: 5px;
    }
    border-radius: 5px;
  }
}
.active-expand {
  transform: rotate(180deg);
}
.panel-not-active {
  margin-top: 0 !important;
  background-color: $background !important;
  & > button {
    padding: 0 16px;
  }
}
.panel-active:focus:before {
  opacity: 0 !important;
}
.panel-active {
  & > [class~='v-list-item'] {
    & > [class~='v-list-item__icon'] {
      & > [class~='v-icon'] {
        color: $text !important;
      }
    }
    & > [class~='v-list-item__content'] > [class~='v-list-item__title'] {
      color: $text !important;
    }
  }
  .title-with-tooltip > .list-title {
    color: $text;
  }
  & > .v-list-item__icon > .v-icon {
    color: $text !important;
  }
  & > .v-list-item__content > .list-title {
    color: $text !important;
  }
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 56px;
}
.active-link > .v-list-item__content > .list-title {
  color: #000 !important;
}
.panel-container {
  width: calc(100% - 1px);
}
.parent-header {
  display: flex;
  & > .v-list-item__icon > .v-icon {
    color: $base-color !important;
  }
  & > .v-list-item__content {
    & > [class~='list-title'] {
      color: $base-color !important;
    }
  }
}
.panel-expand {
  opacity: 0.6;
}
.menu-panel {
  & > div:nth-of-type(2),
  & > div:nth-of-type(3) {
    & > button > .parent-header > div:nth-of-type(1) {
      transform: rotate(180deg);
    }
  }
  & > .v-expansion-panel {
    &:before {
      box-shadow: none !important;
    }
    &:after {
      border: none;
    }
    border-radius: 0 !important;
    & > .v-expansion-panel-content {
      & > .v-expansion-panel-content__wrap {
        & > .v-list-item {
          & > .v-list-item__content {
            & > .v-list-item__title {
              padding-left: 31px !important;
            }
          }
        }
      }
    }
    & > div > .v-expansion-panel-header {
      padding: 0 16px !important;
    }
  }
}
.widthImage {
  width: 101px;
}
.rotate-icon {
  transform: rotate(180deg);
}
.navigation-container {
  padding-top: 0;
}
.tooltip-info {
  white-space: pre;
  color: $white;
  opacity: 1;
}
</style>
