import router from '@/router';
import { defaultState } from './index';
import {
  DEFAULT_LIVE_DATA_REPORT_CONFIG,
  IS_NEW_TAGESGELD_ENABLED,
} from '@/utils/constants';

function updateReports(allReports, data) {
  const result = {};
  Object.keys(allReports).forEach((key) => {
    const mapped = allReports[key].results.map((report) => {
      if (report.slug === data.slug) {
        return {
          ...report,
          note: data.note || '',
          tags: data?.tags || [],
          title: data.title,
          config: data.config,
        };
      }
      return report;
    });
    Object.assign(result, { [key]: { ...allReports[key], results: mapped } });
  });
  return result;
}
function sort(state, { row, icon }) {
  const copyState = { ...state.result };
  const products = copyState.products || [];
  const index = products.findIndex((item) => item.id === row.id);
  icon === 'mdi-chevron-down'
    ? products.splice(index + 1, 0, products.splice(index, 1)[0])
    : products.splice(index - 1, 0, products.splice(index, 1)[0]);
  return Object.assign(copyState, { products });
}
function modifyResult(state, payload) {
  state.result = Object.assign({}, state.result, payload);
}

function modifyReports(reports, payload) {
  const { rows, configKey, ...rest } = payload;
  const items = rows || [];
  const result = Object.assign(rest, { rows: items });

  return {
    ...reports,
    [configKey]: {
      ...reports[configKey],
      results: reports[configKey].results.map((item) =>
        item.key === result.key ? { ...item, ...result } : item
      ),
    },
  };
}
function currentReportType(payload) {
  if (router.currentRoute.params.producttype) {
    return payload.REPORT_TYPE[
      router.currentRoute.params.producttype.toUpperCase()
    ];
  }
  if (router.currentRoute.name) {
    return payload.REPORT_TYPE[router.currentRoute.name.toUpperCase()];
  }
}
export default {
  cancelControllers(state) {
    if (state.abortControllers.length) {
      state.abortControllers.forEach((controller) => {
        controller.abort();
      });
    }
    state.abortControllers = [];
    state.allReportsTagesgeld = {
      latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
    };
    state.allReportsFestgeld = {
      latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
    };
    state.allReportsBaugeld = {
      latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
    };
  },
  clearReports(state, payload) {
    // the payload contains state keys that must be reset to their original state.
    payload.reports.forEach((key) => {
      state[key] = {
        latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
        autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
        other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      };
    });
    payload.loading.forEach((key) => {
      state[key] = false;
    });
  },
  addControllers(state, controllers) {
    state.abortControllers = [...state.abortControllers, ...controllers];
  },
  clearControllers(state) {
    state.abortControllers = [];
  },
  disabled(state, payload) {
    state.isDisabledPicker = payload;
  },
  loadingPreview(state, payload) {
    state.loadingPreview = payload;
  },
  loadingAllReportsBaugeld(state, payload) {
    state.loadingAllReportsBaugeld = payload;
  },
  loadingAllReportsFestgeld(state, payload) {
    state.loadingAllReportsFestgeld = payload;
  },
  loadingAllReportsTagesgeld(state, payload) {
    state.loadingAllReportsTagesgeld = payload;
  },
  loading(state, payload) {
    state.loading = payload;
  },
  uploadReportSuccess(state, payload) {
    state.uploadReport = payload;
  },
  mapSkeleton(state, payload) {
    const { configKey = '', reportType, ...rest } = payload;
    if (reportType === 2) {
      state.allReportsFestgeld = configKey
        ? { ...state.allReportsFestgeld, [configKey]: rest.configs }
        : payload.configs;
    }
    if (reportType === 1 || payload.reportType === 8) {
      state.allReportsTagesgeld = configKey
        ? { ...state.allReportsTagesgeld, [configKey]: rest.configs }
        : payload.configs;
    }
    if (reportType === 3) {
      state.allReportsBaugeld = configKey
        ? { ...state.allReportsBaugeld, [configKey]: rest.configs }
        : payload.configs;
    }
  },
  fetchAllReportsSuccess(state, payload) {
    if (payload.reportType === 3) {
      state.allReportsBaugeld = modifyReports(
        state.allReportsBaugeld,
        payload.payload
      );
      state.updatedReportsBaugeld = false;
    }
    if (payload.reportType === 2) {
      state.allReportsFestgeld = modifyReports(
        state.allReportsFestgeld,
        payload.payload
      );
      state.updatedReportsFestgeld = false;
    }
    if (payload.reportType === 1 || payload.reportType === 8) {
      state.allReportsTagesgeld = modifyReports(
        state.allReportsTagesgeld,
        payload.payload
      );
      state.updatedReportsTagesgeld = false;
    }
  },
  fetchPreviewReportSuccess(state, payload) {
    const productType = {
      1: 'tagesgeld',
      2: 'festgeld',
      3: 'baugeld',
      8: 'tagesgeld',
    };
    state.links = {
      report: `/${productType[+state.report_type]}/report/${payload.slug}`,
      dashboard: `/${productType[+state.report_type]}`,
    };
    const allBanks =
      payload.config?.allBanks?.filter((bank) => bank.includes('_')) || [];
    state.previewReport = payload;
    state.filtersForEditing = payload.config;
    state.dataTable = { ...state.dataTable, allBanks };
  },
  setPreviewReport(state, payload) {
    const { headers, rows } = payload;
    state.dataTable = Object.assign(
      state.dataTable,
      { headers: headers, rows: rows },
      {}
    );
  },
  setUpdateReport(state, payload) {
    const allBanks =
      payload.config?.allBanks?.filter((bank) => bank.includes('_')) || [];
    state.dataTable = { ...state.dataTable, allBanks };
    state.filtersForEditing = payload.config;
    state.newReport = {
      title: payload.title,
      uid: payload.uid,
      slug: payload.slug,
      report_type: payload.report_type,
    };
  },
  setUpdateExistingReport(state, payload) {
    const { config, ...rest } = payload;
    const allBanks =
      config?.allBanks?.filter((bank) => bank.includes('_')) || [];
    state.filtersForEditing = Object.assign(
      config,
      { tags: rest.tags ? rest.tags : [], note: rest.note ? rest.note : null },
      {}
    );
    state.dataTable = { ...state.dataTable, allBanks };
    state.updateExistingReport = {
      title: payload.title,
      uid: payload.uid,
      slug: payload.slug,
      report_type: payload.report_type,
    };
  },
  updateReportSuccess(state, payload) {
    if (payload.productType && payload.slug) {
      state.links = {
        report: `/${payload.productType}/report/${payload.slug}`,
        dashboard: `/${payload.productType}`,
      };
    }
    state.newReport = Object.assign(state.newReport, payload.payload);
    state.singleReport = Object.assign(state.newReport, payload.payload);
    state.editReport = payload.editReport;
    state.previewReport = {};
    state.updatedReportsTagesgeld = payload.productType === 'tagesgeld';
    state.updatedReportsFestgeld = payload.productType === 'festgeld';
    state.updatedReportsBaugeld = payload.productType === 'baugeld';
  },
  updateExistingReportSuccess(state, payload) {
    if (payload.productType && payload.slug) {
      state.links = {
        report: `/${payload.productType}/report/${payload.slug}`,
        dashboard: `/${payload.productType}`,
      };
    }
    state.singleReport = Object.assign(
      state.updateExistingReport,
      payload.payload
    );
    state.error = false;
    state.loading = false;
    state.editReport = payload.editReport;
    state.previewReport = {};
    state.updatedReportsTagesgeld = payload.productType === 'tagesgeld';
    state.updatedReportsFestgeld = payload.productType === 'festgeld';
    state.updatedReportsBaugeld = payload.productType === 'baugeld';
  },
  setFetchSingleReport(state, payload) {
    state.singleReport = payload;
    state.report_type = payload.report_type;
  },
  fetchSingleReportSuccess(state, payload) {
    state.isDisabledPicker = false;
    const { rows, ...rest } = payload;
    state.uploadedReports = {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    };
    state.singleReport = {
      ...state.singleReport,
      ...rest,
      rows: rows || [],
    };
    state.loading = false;
    state.uploadReport = true;
    if (payload.reportType) {
      state.singleReport.report_type = payload.reportType;
      state.report_type = payload.reportType;
    }
  },
  setEditReport(state, payload) {
    const { config, ...res } = payload;
    const { allBanks, ...rest } = config;
    state.disabledModalFields = {
      product_tilgung_manual: false,
    };
    if (allBanks?.length) {
      Object.assign(rest, { anbieterAuswahl: allBanks }, {});
      state.result = {
        ...state.result,
        ...rest,
        selection_title: res.title,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
      };
      state.filtersForEditing = {
        ...rest,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
        selection_title: res.title,
      };
    } else {
      state.result = {
        ...state.result,
        ...config,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
        selection_title: res.title,
      };
      state.filtersForEditing = {
        ...config,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
        selection_title: res.title,
      };
    }

    state.editReport = true;
    state.previewReport = {};
    state.updateExistingReport = {
      title: payload.title,
      uid: payload.uid,
      slug: payload.slug,
      report_type: Number(payload.report_type) === 1 ? 8 : payload.report_type,
    };
    state.newReport = {};
    state.loading = false;
  },
  saveSelectedCheckboxes(state, payload) {
    return modifyResult(state, payload);
  },
  setSelectedValue(state, payload) {
    return modifyResult(state, payload);
  },
  error(state, payload) {
    state.error = payload;
  },
  clearSingleReport(state) {
    state.singleReport = {};
    state.uploadReport = false;
    state.disabledModalFields = {
      product_tilgung_manual: false,
    };
  },
  clearResult(state) {
    state.disabledModalFields = {
      product_tilgung_manual: false,
    };
    state.links = {};
    state.uploadedReports = {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    };
    state.allReportsFestgeld = {
      latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
    };
    state.allReportsBaugeld = {
      latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
    };
    state.allReportsTagesgeld = {
      latest: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      autoUpdate: DEFAULT_LIVE_DATA_REPORT_CONFIG,
      other: DEFAULT_LIVE_DATA_REPORT_CONFIG,
    };
    state.result = {
      banken_vermittlerangebote: 0,
      products: [],
    };
    state.filtersForEditing = {};
    state.sumMeta = 0;
    state.loading = false;
    state.editReport = false;
    state.newReport = {};
    state.singleReport = {};
    state.error = false;
    state.dataTable = {};
    state.previewReport = {};
    state.updateExistingReport = {};
  },
  resetDataTable(state) {
    state.dataTable = {};
  },
  resetError(state) {
    state.error = false;
  },
  setReportType(state, payload) {
    if (currentReportType(payload) === 1) {
      state.report_type = IS_NEW_TAGESGELD_ENABLED ? 8 : 1;
    } else {
      state.report_type = currentReportType(payload);
    }
    state.loading = false;
  },
  saveSumMeta(state, payload) {
    state.sumMeta = payload;
  },
  setProductsFilters(state, payload) {
    state.result = {
      ...state.result,
      products: state.result.products.concat([{ ...payload }]),
    };
  },
  deleteProduct(state, payload) {
    state.result = {
      ...state.result,
      products: [...state.result.products].filter(
        (item) => item.id !== payload
      ),
    };
  },
  changeExistingProduct(state, payload) {
    const newArr = [...state.result.products].map((i) => {
      if (i.id === payload.id) return { ...payload };
      return { ...i };
    });
    state.result = {
      ...state.result,
      products: newArr,
    };
  },
  sortProducts(state, payload) {
    return sort(state, payload);
  },
  resetSingleReport(state) {
    state.singleReport = {};
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
  },
  setUploadedReports(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state.uploadedReports[key] = val;
  },
  setClassDisabled(state, payload) {
    state.disabledModalFields = { ...state.disabledModalFields, ...payload };
  },
  modifySelectionDialog(state, payload) {
    state.isSelectionDialog = payload;
  },
  updateReport(state, data) {
    state.singleReport = {
      ...data,
      headers: state.singleReport.headers || data.headers,
      rows: state.singleReport.rows || data.rows,
      meta: state.singleReport.meta || data.meta,
    };
    state.uploadReport = true;
    switch (data.report_type) {
      case 8:
        return (state.allReportsTagesgeld = updateReports(
          state.allReportsTagesgeld,
          data
        ));
      case 1:
        return (state.allReportsTagesgeld = updateReports(
          state.allReportsTagesgeld,
          data
        ));
      case 2:
        return (state.allReportsFestgeld = updateReports(
          state.allReportsFestgeld,
          data
        ));
      case 3:
        return (state.allReportsBaugeld = updateReports(
          state.allReportsBaugeld,
          data
        ));
      default:
        return state;
    }
  },
  setSnackBarData(state, payload) {
    state.snackbarData = {
      ...state.snackbarData,
      ...{
        text: payload.text,
        show: payload.show,
        timeout: payload.timeout,
        color: payload?.color || '',
        dark: payload?.dark || true,
      },
    };
  },
  setDashboardTop(state, payload) {
    state.dashboardTop = payload;
  },
  setAvailableArchiveDates(state, payload) {
    state.archiveDates = payload;
  },
  updateCopyConfig(state, config) {
    state.copyResult = config;
  },
};
