export default {
  isAuthorizationExpired(state) {
    return state.isaAuthorizationExpired;
  },
  isAuthorized(state) {
    return state.isAuthorized;
  },
  getSnackbarData(state) {
    return state.snackbarData;
  },
  getLoading(state) {
    return state.loading;
  },
  getPermissions(state) {
    return state.permissions;
  },
  getUser(state) {
    const firstName = state.user?.givenName || '';
    const lastName = state.user?.familyName || '';
    return {
      ...state.user,
      fullName:
        firstName && lastName
          ? `${firstName} ${lastName}`
          : state.user?.email || '',
    };
  },
};
