import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export function defaultState() {
  return {
    uploadedReports: {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    },
    loadingAllReports: {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    },
    singleReport: {
      title: '',
      data: [],
      chartParam: {
        datasetColor1: 2,
        datasetColor2: 0,
        datasetColor3: 3,
        datasetColor4: 1,
        datasetColor5: 4,
        chartMinValue: true,
        chartMaxValue: true,
        chartAvgValue: true,
        zins1: true,
        zins2: true,
        zins3: true,
        zins4: true,
        zins5: true,
      },
      formParam: {
        bankname1: '',
        bankname2: '',
        bankname3: '',
        bankname4: '',
        bankname5: '',
        vermittlerangebote: { value: 0, selected: false },
      },
    },
    copySingleReport: {
      title: '',
      data: [],
      chartParam: {
        datasetColor1: 2,
        datasetColor2: 0,
        datasetColor3: 3,
        datasetColor4: 1,
        datasetColor5: 4,
        chartMinValue: true,
        chartMaxValue: true,
        chartAvgValue: true,
        zins1: true,
        zins2: true,
        zins3: true,
        zins4: true,
        zins5: true,
      },
      formParam: {
        bankname1: '',
        bankname2: '',
        bankname3: '',
        bankname4: '',
        bankname5: '',
        vermittlerangebote: { value: 0, selected: false },
      },
    },
    allReportsFestgeld: [],
    allReportsTagesgeld: [],
    allReportsBaugeld: [],
    error: false,
    loading: false,
  };
}
const state = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
