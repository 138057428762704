import { instance } from '../../../../../src/utils';
import axios from 'axios';

import eachOfLimit from 'async/eachOfLimit';
import { TYPES, URLS } from '../../../utils/constants';

const reqExp =
  /(&)(?!bankname|zeitraum|anlagebetrag|anlagedauer|vermittlerangebote|zeitraumVon|zeitraumBis|kundenkreis|sollzinsbindung|beleihung|chartMaxValue|chartMinValue|chartAvgValue)/g;
export default {
  async saveReport({ commit }, payload) {
    commit('loading', true);
    const type = TYPES[payload.report_type];
    try {
      const result = {};
      const { data } = await instance.post('/api/wettbewerb/', payload);
      Object.assign(result, data);
      await axios
        .get(`${URLS[data.report_type]}index?${data.config.url}`)
        .then((res) => Object.assign(result, res.data));
      commit('updateReports', { type, result });
    } catch (e) {
      commit('loading', false);
      commit('loading', true);
    }
  },
  fetchNewReportData({ commit }, payload) {
    commit('loading', true);
    const { config, reportType } = payload;
    try {
      axios.get(`${URLS[reportType]}index?${config.url}`).then((res) => {
        commit('updateSingleReport', res.data);
      });
    } catch (e) {
      commit('loading', false);
    }
  },
  async updateReport({ commit }, payload) {
    commit('loading', true);
    const { slug, config, reportType, title } = payload;
    const type = TYPES[reportType];
    try {
      const result = {};
      const { data } = await instance.patch(`/api/wettbewerb/${slug}/`, {
        config,
        title,
      });
      Object.assign(result, data);
      await axios
        .get(`${URLS[data.report_type]}index?${data.config.url}`)
        .then((res) => Object.assign(result, res.data));
      commit('updateReport', { type, result });
      commit('loading', false);
    } catch (e) {
      commit('loading', false);
    }
  },
  deleteReport({ commit }, payload) {
    commit('loading', true);
    try {
      instance.delete(`/api/wettbewerb/${payload.slug}/`).then(() => {
        commit('updateAllReports', payload);
      });
    } catch (e) {
      commit('loading', false);
      commit('loading', true);
    }
  },
  async fetchSingleReport({ commit }, slug) {
    commit('loading', true);
    try {
      const result = {};
      const { data } = await instance(`/api/wettbewerb/${slug}/`);
      Object.assign(result, data);
      const url = result.config.url.replace(reqExp, encodeURIComponent('&'));
      await axios
        .get(`${URLS[result.report_type]}index?${url}`)
        .then((res) => Object.assign(result, res.data));
      commit('setSingleReport', result);
    } catch (e) {
      commit('error', true);
      commit('loading', false);
    }
  },
  async fetchWettbewerbData({ commit }, reportType) {
    const type = TYPES[reportType];
    commit('loadingAllReports', { [TYPES[reportType]]: true });
    try {
      const { data } = await instance('/api/wettbewerb/', {
        params: { report_type: reportType },
      });
      const result = data
        .sort((a, b) => {
          return (
            new Date(b.timestamp).getTime() ||
            0 - new Date(a.timestamp).getTime() ||
            0
          );
        })
        .map((item, index) => {
          return Object.assign(item, { key: index });
        });

      commit('mapSkeleton', { result, type });
      commit('setUploadedReports', { [type]: true });
      await eachOfLimit(result, 3, async (item, key) => {
        const url = item.config.url.replace(reqExp, encodeURIComponent('&'));
        try {
          const fetchReportData = await axios
            .get(`${URLS[reportType]}index?${url}`)
            .then((res) => res.data)
            .catch(() => {
              const payload = {
                ...result[key],
                error: true,
              };
              commit('fetchAllReportsSuccess', {
                payload,
                type,
              });
            });
          const payload = {
            ...fetchReportData,
            ...result[key],
          };
          commit('fetchAllReportsSuccess', {
            payload,
            type,
          });
        } catch (e) {
          commit('loadingAllReports', { [TYPES[reportType]]: false });
          commit('error', true);
        }
      });
      commit('loadingAllReports', { [TYPES[reportType]]: false });
    } catch (e) {
      if (e) {
        commit('setUploadedReports', { [type]: false });
        commit('loadingAllReports', { [TYPES[reportType]]: false });
        commit('error', true);
        console.log(e.response);
      }
    }
  },
};
