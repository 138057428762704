import axios from 'axios';
import { URLS } from '../../utils/constants';

function defaultState() {
  return {
    chartDataFestGeld: {
      show: false,
      data: [],
    },
    chartDataHypotheken: {
      show: false,
      data: [],
    },
    chartData: {
      show: false,
      data: [],
    },
  };
}

const state = defaultState();

const getters = {
  getChartDataTagesgeld() {
    return state.chartData;
  },
  getChartDataFestGeld() {
    return state.chartDataFestGeld;
  },
  getChartDataHypotheken() {
    return state.chartDataHypotheken;
  },
};

const actions = {
  loadChartDataFestGeldFromLocalStorage({ commit }) {
    let data = null;
    const chartData = localStorage.getItem('chartDataFestGeld');
    if (chartData) {
      try {
        data = JSON.parse(chartData);
        commit('setChartDataFestGeldFromLocalStorage', data);
      } catch (e) {
        localStorage.removeItem('chartDataFestGeld');
      }
    }
  },
  loadChartDataHypothekenFromLocalStorage({ commit }) {
    let data = null;
    const chartData = localStorage.getItem('chartDataHypotheken');
    if (chartData) {
      try {
        data = JSON.parse(chartData);
        commit('setChartDataHypothekenFromLocalStorage', data);
      } catch (e) {
        localStorage.removeItem('chartDataHypotheken');
      }
    }
  },
  async loadChartDataFestGeld({ commit }, payload) {
    const { zeitraum, zeitraumBis, zeitraumVon, vermittlerangebote, ...rest } =
      payload;
    let param = {};
    if (zeitraum !== 0) {
      param = Object.assign(rest, {
        zeitraum: zeitraum,
        vermittlerangebote: vermittlerangebote.value,
      });
    }
    if (zeitraum === 0 && (zeitraumBis === null || zeitraumVon === null)) {
      param = Object.assign(rest, {
        zeitraum: zeitraum,
        vermittlerangebote: vermittlerangebote.value,
      });
    }
    if (zeitraum === 0 && (zeitraumBis !== null || zeitraumVon !== null)) {
      param = Object.assign(
        rest,
        {
          vermittlerangebote: vermittlerangebote.value,
        },
        { zeitraumBis: zeitraumBis },
        { zeitraumVon: zeitraumVon }
      );
    }

    await axios
      // festgeld
      .get(`${URLS[5]}index`, {
        params: param,
      })
      .then((response) => {
        commit('setChartDataFestGeld', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadChartDataHypotheken({ commit }, payload) {
    const { zeitraum, zeitraumBis, zeitraumVon, ...rest } = payload;
    let param = {};
    if (zeitraum !== 0) {
      param = Object.assign(rest, { zeitraum: zeitraum });
    }
    if (zeitraum === 0 && (zeitraumBis === null || zeitraumVon === null)) {
      param = Object.assign(rest, { zeitraum: zeitraum });
    }
    if (zeitraum === 0 && (zeitraumBis !== null || zeitraumVon !== null)) {
      param = Object.assign(
        rest,
        { zeitraumBis: zeitraumBis },
        { zeitraumVon: zeitraumVon }
      );
    }

    await axios
      // baugeld
      .get(`${URLS[6]}index`, {
        params: param,
      })
      .then((response) => {
        commit('setChartDataHypotheken', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadChartDataAsync({ commit }, param) {
    const { vermittlerangebote, ...rest } = param;
    const modified = Object.assign(rest, {
      vermittlerangebote: vermittlerangebote.value,
    });
    await axios
      // tagesgeld
      .get(`${URLS[4]}index`, { params: modified })
      .then((response) => {
        commit('setChartData', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

const mutations = {
  setChartDataFestGeld(state, payload) {
    state.chartDataFestGeld = { show: true, data: payload || [] };
    localStorage.setItem(
      'chartDataFestGeld',
      JSON.stringify(state.chartDataFestGeld)
    );
  },
  setChartDataFestGeldFromLocalStorage(state, payload) {
    state.chartDataFestGeld = payload;
  },
  setChartDataHypothekenFromLocalStorage(state, payload) {
    state.chartDataHypotheken = payload;
  },
  setChartDataHypotheken(state, payload) {
    state.chartDataHypotheken = { show: true, data: payload || [] };
    localStorage.setItem(
      'chartDataHypotheken',
      JSON.stringify(state.chartDataHypotheken)
    );
  },
  setChartData(state, payload) {
    state.chartData = { show: true, data: payload || [] };
    localStorage.setItem('chartData', JSON.stringify(state.chartData));
  },
  resetStateHypotheken(state) {
    state.chartDataHypotheken = {
      show: false,
      data: [],
    };
    localStorage.removeItem('chartDataHypotheken');
  },
  resetStateFestGeld(state) {
    state.chartDataFestGeld = {
      show: false,
      data: [],
    };
    localStorage.removeItem('chartDataFestGeld');
  },
  resetStateTagesgeld(state) {
    state.chartData = {
      show: false,
      data: [],
    };
    localStorage.removeItem('resultParam');
  },
  resetState(state) {
    Object.assign(state, defaultState());
    localStorage.removeItem('chartData');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
