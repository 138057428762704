import actions from './actions';
import mutations from './mutations';
import getters from './getters';

function setDates() {
  const date = new Date();
  const startDate = new Date(date.setMonth(date.getMonth() - 6))
    .toISOString()
    .substring(0, 10);
  const currentDate = new Date().toISOString().substring(0, 10);
  return [startDate, currentDate];
}
export function defaultState() {
  return {
    kfwSecondTable: {
      default: {
        rows: [],
        headers: [],
      },
      festgeld: {
        rows: [],
        headers: [],
      },
      tagesgeld: {
        rows: [],
        headers: [],
      },
      baugeld: {
        rows: [],
        headers: [],
      },
    },
    kfwFirstTable: {
      default: {
        rows: [],
        headers: [],
      },
      festgeld: {
        rows: [],
        headers: [],
      },
      tagesgeld: {
        rows: [],
        headers: [],
      },
      baugeld: {
        rows: [],
        headers: [],
      },
    },
    dataTable: {
      default: {
        rows: [],
        headers: [],
      },
      festgeld: {
        rows: [],
        headers: [],
      },
      tagesgeld: {
        rows: [],
        headers: [],
      },
      baugeld: {
        rows: [],
        headers: [],
      },
    },
    formKfwTagesgeld: {
      productType: 'tagesgeld',
      anlagebetrag: 5000,
      zeitraum: null,
    },
    formKfwBaugeld: {
      productType: 'baugeld',
      dates: setDates(),
      sollzinsbindung: 0,
    },
    formParamFestgeld: {
      productType: 'festgeld',
      anlagedauer: 3,
      zeitraum: null,
      datePicker: {
        dates: [],
      },
      berechnung: {},
    },
    formParamTagesgeld: {
      productType: 'tagesgeld',
      anlagebetrag: 5000,
      zeitraum: null,
      datePicker: {
        dates: [],
      },
      berechnung: {},
    },
    formParamBaugeld: {
      productType: 'baugeld',
      zeitraum: null,
      sollzinsbindung: 5,
      beleihung: 55,
      nutzung: 1,
      bankKategorie: 0,
      datePicker: {
        dates: [],
      },
      berechnung: {},
    },
    loading: false,
    error: {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    },
    errorKfw: {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    },
  };
}

const state = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
