import { getField, updateField } from 'vuex-map-fields';

export const DEFAULT_FORM_PARAM_TAGESGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  anlagebetrag: 10000,
  zeitraum: 4,
  zeitraumVon: null,
  zeitraumBis: null,
  kundenkreis: 0,
  vermittlerangebote: { value: 0, selected: false },
};
export const DEFAULT_FORM_PARAM_BAUGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  zeitraum: 4,
  zeitraumVon: null,
  zeitraumBis: null,
  sollzinsbindung: 10,
  beleihung: 60,
};
export const DEFAULT_FORM_PARAM_FESTGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  zeitraum: 4,
  zeitraumVon: null,
  zeitraumBis: null,
  kundenkreis: 0,
  vermittlerangebote: { value: 0, selected: false },
  anlagebetrag: 50000,
  anlagedauer: 1,
};
const DEFAULT_CHART_PARAM_FESTGELD = {
  datasetColor1: 2,
  datasetColor2: 0,
  datasetColor3: 3,
  datasetColor4: 1,
  datasetColor5: 4,
  chartMinValue: true,
  chartMaxValue: true,
  chartAvgValue: true,
  zins1: true,
  zins2: true,
  zins3: true,
  zins4: true,
  zins5: true,
};
const DEFAULT_CHART_PARAM_BAUGELD = {
  datasetColor1: 2,
  datasetColor2: 0,
  datasetColor3: 3,
  datasetColor4: 1,
  datasetColor5: 4,
  chartMinValue: true,
  chartMaxValue: true,
  chartAvgValue: true,
  zins1: true,
  zins2: true,
  zins3: true,
  zins4: true,
  zins5: true,
};

function defaultState() {
  return {
    formParam: { ...DEFAULT_FORM_PARAM_TAGESGELD },
    copyFormParam: { ...DEFAULT_FORM_PARAM_TAGESGELD },
    forceUpdate: false,
    formParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
    copyFormParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
    formParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
    copyFormParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
    chartParamFestGeld: { ...DEFAULT_CHART_PARAM_FESTGELD },
    chartParamHypotheken: { ...DEFAULT_CHART_PARAM_BAUGELD },
    chartParam: {
      datasetColor1: 2,
      datasetColor2: 0,
      datasetColor3: 3,
      datasetColor4: 1,
      datasetColor5: 4,
      chartMinValue: true,
      chartMaxValue: true,
      chartAvgValue: true,
      zins1: true,
      zins2: true,
      zins3: true,
      zins4: true,
      zins5: true,
    },
    resultParam: {
      showResult: false,
    },
  };
}

const state = defaultState();

const getters = {
  getField,
  getLoading() {
    return state.loading;
  },
  getForceUpdate(state) {
    return state.forceUpdate;
  },
  getFormParam() {
    return state.formParam;
  },
  getCopyFormParam() {
    return state.copyFormParam;
  },
  getFormParamHypotheken() {
    return state.formParamHypotheken;
  },
  getCopyFormParamHypotheken() {
    return state.copyFormParamHypotheken;
  },
  getFormParamFestgeld() {
    return state.formParamFestGeld;
  },
  getCopyFormParamFestgeld() {
    return state.copyFormParamFestGeld;
  },
  getChartParamHypotheken() {
    return state.chartParamHypotheken;
  },
  getChartParamFestgeld() {
    return state.chartParamFestGeld;
  },
  getChartParam() {
    return state.chartParam;
  },
  getResultParam() {
    return state.resultParam;
  },
  getFieldValue: (state) => (path) => {
    let paths = path.split('.');
    if (paths.length === 2) {
      return state[paths[0]][paths[1]];
    } else {
      return state[paths[0]];
    }
  },
};

const actions = {
  saveParam() {
    localStorage.setItem('formParam', JSON.stringify(state.formParam));
    localStorage.setItem('chartParam', JSON.stringify(state.chartParam));
    localStorage.setItem('resultParam', JSON.stringify(state.resultParam));
  },
  loadParamFestGeld({ commit }) {
    let param = null;
    let chartData = null;
    const formParam = localStorage.getItem('formParamFestGeld');
    const chartParam = localStorage.getItem('chartParamFestGeld');
    if (chartParam) {
      try {
        chartData = JSON.parse(chartParam);
        commit('setChartParamFestGeld', chartData);
      } catch (e) {
        localStorage.removeItem('chartParamFestGeld');
      }
    }
    if (formParam) {
      try {
        param = JSON.parse(formParam);
        if (param.kundenkreis === 1) {
          param.kundenkreis = 0;
        }
        commit('setFormParamFestGeld', param);
      } catch (e) {
        localStorage.removeItem('formParamFestGeld');
      }
    }
  },
  loadParamHypotheken({ commit }) {
    let param = null;
    let chartData = null;
    const formParam = localStorage.getItem('formParamHypotheken');
    const chartParam = localStorage.getItem('chartParamHypotheken');
    if (chartParam) {
      try {
        chartData = JSON.parse(chartParam);
        commit('setChartParamHypotheken', chartData);
      } catch (e) {
        localStorage.removeItem('chartParamHypotheken');
      }
    }
    if (formParam) {
      try {
        param = JSON.parse(formParam);
        commit('setFormParamHypotheken', param);
      } catch (e) {
        localStorage.removeItem('formParamHypotheken');
      }
    }
  },
  loadParam({ commit }) {
    let param = null;
    if (localStorage.getItem('formParam')) {
      try {
        param = JSON.parse(localStorage.getItem('formParam'));
        if (param.kundenkreis === 1) {
          param.kundenkreis = 0;
        }
        commit('setFormParam', param);
      } catch (e) {
        localStorage.removeItem('formParam');
      }
    }
    if (localStorage.getItem('chartParam')) {
      try {
        param = JSON.parse(localStorage.getItem('chartParam'));
        commit('setChartParam', param);
      } catch (e) {
        localStorage.removeItem('chartParam');
      }
    }
    if (localStorage.getItem('resultParam')) {
      try {
        param = JSON.parse(localStorage.getItem('resultParam'));
        commit('setResultParam', param);
      } catch (e) {
        localStorage.removeItem('resultParam');
      }
    }
  },
};

const mutations = {
  updateField,
  resetParamTagesgeld(state) {
    state.resultParam = {
      showResult: false,
    };
  },
  updateCopyForm(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state[key] = val;
  },
  updateChartParam(state, { path, value }) {
    let paths = path.split('.');
    state[paths[0]] = { ...state[paths[0]], [paths[1]]: value };
  },
  setValueMaxAvgMin(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state[key] = {
      ...state[key],
      ...{
        chartMinValue: val,
        chartMaxValue: val,
        chartAvgValue: val,
      },
    };
  },
  updateVermittlerangebote(state, payload) {
    const { field, ...rest } = payload;

    state[field].vermittlerangebote = {
      value: rest.value,
      selected: rest.selected,
    };
    localStorage.setItem(field, JSON.stringify(state[field]));
  },
  saveParamHypoteken(state) {
    state.forceUpdate = false;
    localStorage.setItem(
      'formParamHypotheken',
      JSON.stringify(state.formParamHypotheken)
    );
    localStorage.setItem(
      'chartParamHypotheken',
      JSON.stringify(state.chartParamHypotheken)
    );
  },
  saveParamFestGeld(state) {
    state.forceUpdate = false;
    localStorage.setItem(
      'formParamFestGeld',
      JSON.stringify(state.formParamFestGeld)
    );
    localStorage.setItem(
      'chartParamFestGeld',
      JSON.stringify(state.chartParamFestGeld)
    );
  },
  setFormParamHypotheken(state, param) {
    state.formParamHypotheken = { ...state.formParamHypotheken, ...param };
    state.copyFormParamHypotheken = {
      ...state.copyFormParamHypotheken,
      ...param,
    };
  },
  setFormParamFestGeld(state, param) {
    state.formParamFestGeld = { ...state.formParamFestGeld, ...param };
    state.copyFormParamFestGeld = { ...state.copyFormParamFestGeld, ...param };
  },
  setChartParamHypotheken(state, param) {
    state.chartParamHypotheken = { ...state.chartParamHypotheken, ...param };
  },
  setChartParamFestGeld(state, param) {
    state.chartParamFestGeld = { ...state.chartParamFestGeld, ...param };
  },
  setFormParam(state, param) {
    state.formParam = { ...state.formParam, ...param };
    state.copyFormParam = { ...state.copyFormParam, ...param };
  },
  setChartParam(state, param) {
    state.chartParam = param;
  },
  setResultParam(state, param) {
    state.resultParam = param;
  },
  setFieldValue(state, { path, value, hypotheken, festGeld }) {
    let paths = path.split('.');
    if (!hypotheken && !festGeld) {
      if (paths.length === 2) {
        state[paths[0]] = { ...state[paths[0]], [paths[1]]: value };
      } else {
        state[paths[0]] = { ...state[paths[0]], [paths[0]]: value };
      }
      localStorage.setItem('formParam', JSON.stringify(state.formParam));
      localStorage.setItem('chartParam', JSON.stringify(state.chartParam));
      localStorage.setItem('resultParam', JSON.stringify(state.resultParam));
    }
    if (hypotheken && !festGeld) {
      if (paths[0] === 'chartParamHypotheken') {
        const result = {
          ...state.chartParamHypotheken,
          [paths[1]]: value,
        };
        state.chartParamHypotheken = result;
        localStorage.setItem('chartParamHypotheken', JSON.stringify(result));
      } else {
        const result = {
          ...state.formParamHypotheken,
          [paths[1]]: value,
        };
        state.formParamHypotheken = result;
        localStorage.setItem('formParamHypotheken', JSON.stringify(result));
      }
    }
    if (festGeld && !hypotheken) {
      if (paths[0] === 'chartParamFestGeld') {
        const result = {
          ...state.chartParamFestGeld,
          [paths[1]]: value,
        };
        state.chartParamFestGeld = result;
        localStorage.setItem('chartParamFestGeld', JSON.stringify(result));
      } else {
        const result = {
          ...state.formParamFestGeld,
          [paths[1]]: value,
        };
        state.formParamFestGeld = result;
        localStorage.setItem('formParamFestGeld', JSON.stringify(result));
      }
    }
  },
  resetStateHypotheken(state) {
    Object.assign(
      state,
      {
        formParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
        copyFormParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
      },
      {
        chartParamHypotheken: { ...DEFAULT_CHART_PARAM_BAUGELD },
      },
      {}
    );
    state.forceUpdate = true;
    localStorage.removeItem('formParamHypotheken');
  },
  resetStateFestGeld(state) {
    Object.assign(
      state,
      {
        formParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
        copyFormParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
      },
      {
        chartParamFestGeld: { ...DEFAULT_CHART_PARAM_FESTGELD },
      },
      {}
    );
    state.forceUpdate = true;
    localStorage.removeItem('formParamFestGeld');
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
    localStorage.removeItem('formParam');
    localStorage.removeItem('chartParam');
    localStorage.removeItem('resultParam');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
