export default {
  rowsForBaugeldTables(state) {
    return state.rowsForBaugeldTables;
  },
  editRow(state) {
    return state.editRow;
  },
  copyProduct(state) {
    return state.copyProduct;
  },
};
