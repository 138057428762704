import { instance } from '@/utils';
import apolloProvider from '../../apollo/apollo-provider';
import gql from 'graphql-tag';

export default {
  saveChartData({ commit }, payload) {
    commit('loading', true);
    return instance.post('/api/zinschart/', payload);
  },
  fetchZinsChartData({ commit }, id) {
    commit('loading', true);
    return instance.get(`/api/zc/${id}/`);
  },
  async fetchInterestChartData({ commit }, payload) {
    commit('loading', true);
    const { params, fields, chartType } = payload;
    try {
      if (chartType === 'base') {
        const QUERY = gql`
            query GetZinschart($zeitraumVon: Date, $zeitraumBis: Date, $produkte: [String]) {
                zinschart(zeitraumVon: $zeitraumVon, zeitraumBis: $zeitraumBis, produkte: $produkte ) {
                    data {
                        bottomChart {
                            datum
                            zins1
                            zins2
                            zins3
                            ${fields.join('\n')}
                        }
                    }
                }
            }
        `;

        const res = await apolloProvider.defaultClient.query({
          query: QUERY,
          variables: params,
        });
        if (res?.data?.zinschart?.data?.bottomChart?.length) {
          if (chartType === 'base') {
            commit('setBaseChartData', res.data.zinschart.data.bottomChart);
          }
        }
        commit('loading', false);
        return res?.data?.zinschart?.data?.bottomChart?.length
          ? res.data.zinschart.data.bottomChart
          : [];
      } else {
        const QUERY = gql`
          query GetSpezialchart(
            $zeitraumVon: Date
            $zeitraumBis: Date
            $produkte: [String]
          ) {
            spezialchart(
              zeitraumVon: $zeitraumVon
              zeitraumBis: $zeitraumBis
              produkte: $produkte
            ) {
              data {
                chart {
                  datum
                  zins1
                  zins2
                  zins3
                }
              }
            }
          }
        `;

        const res = await apolloProvider.defaultClient.query({
          query: QUERY,
          variables: params,
        });
        commit('loading', false);
        return res?.data?.spezialchart?.data?.chart || [];
      }
    } catch (e) {
      commit('loading', false);
      commit('error', true);
    }
  },
};
