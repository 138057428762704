import { defaultState } from './index';

function modifyMaxColumn(state, payload) {
  switch (payload) {
    case 1:
      return (state.maxColumns = state.defaultCountColumns - 2);
    case 2:
      return (state.maxColumns = state.defaultCountColumns - 4);
    case 3:
      return (state.maxColumns = state.defaultCountColumns - 6);
    case 4:
      return (state.maxColumns = state.defaultCountColumns - 8);
    case 5:
      return (state.maxColumns = state.defaultCountColumns - 10);
    default:
      return state.maxColumns;
  }
}
export default {
  clearSection(state) {
    state.sections = [];
  },
  resetError(state) {
    state.error = false;
  },
  fetchWizardConfigFestgeldSuccess(state, payload) {
    state.sections = payload;
  },
  fetchWizardConfigTagesgeldSuccess(state, payload) {
    state.sections = payload;
  },
  fetchWizardConfigBaugeldSuccess(state, payload) {
    state.defaultCountColumns = payload.max_columns;
    state.sections = payload.sections;
  },
  setMaxColumn(state, payload) {
    return modifyMaxColumn(state, payload);
  },
  loading(state, payload) {
    state.loading = payload;
  },
  error(state, payload) {
    state.error = payload;
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
  },
};
