import {
  createHttpLink,
  InMemoryCache,
  ApolloClient,
} from '@apollo/client/core';
import { ApolloLink, concat } from 'apollo-link';

import { BACKEND_URL } from '@/utils/constants';

const httpLink = createHttpLink({
  uri: `${BACKEND_URL}/api/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Token ${token}` : '',
    },
  });
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      AnbieterprofileResultItem: {
        keyFields: ['id', 'finanzdaten', 'landname'],
      },
    },
  }),
});

export default apolloClient;
