function defaultState() {
  return {
    chartColors: [
      { color: '#005c66', text: 'green', icon: 'gruen', value: 0 },
      { color: '#ff3339', text: 'red', icon: 'hellblau', value: 1 },
      { color: '#00a1b3', text: 'darkBlue', icon: 'dunkelblau', value: 2 },
      { color: '#7b0051', text: 'purple', icon: 'violett', value: 3 },
      { color: '#ffd266', text: 'yellow', icon: 'rot', value: 4 },
      { color: '#8ce071', text: 'lime', icon: 'orange', value: 5 },
    ],
  };
}

const state = defaultState();

const getters = {
  getColor: (state) => (index) => {
    return state.chartColors[index];
  },
};

const actions = {
  // evtl. vom Server laden
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
