import Vue from 'vue';
import VueI18n from 'vue-i18n';

import de from '@/utils/locales/de';
import en from '@/utils/locales/en';

Vue.use(VueI18n);

const messages = {
  en,
  de,
};

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
});
