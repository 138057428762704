<template>
  <div v-click-outside="hideMenu">
    <div class="menu-btn">
      <button
        @click="openMenu"
        class="toggle-btn"
        v-bind:class="{ toggleBtnActive: open }"
      >
        <span />
      </button>
    </div>
    <div class="sidebar" v-bind:class="{ active: open }">
      <div class="main-menu">
        <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        <router-link :to="{ name: 'settings' }">Settings</router-link>
        <button>Logout</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';

@Component({
  directives: {
    ClickOutside,
  },
})
export default class Menu extends Vue {
  open: Boolean = false;

  openMenu() {
    this.open = !this.open;
  }

  hideMenu() {
    this.open = false;
  }
  goWizard() {
    this.$router.push('/dashboard');
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/function';

.menu-btn {
  position: absolute;
  top: 5px;
  right: 0;
}
.toggle-btn {
  z-index: 6;
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  background-color: inherit;
  border: none;
  outline: none !important;
  span,
  span::before,
  span::after {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 50%;
    margin-left: -14px;
    width: 28px;
    height: 3px;
    background: $black;
  }
  span::before,
  span::after {
    content: '';
    display: block;
    transition: 0.6s;
  }
  span::before {
    transform: translateY(-7px);
  }
  span::after {
    transform: translateY(7px);
  }
}

.open-menu a::after {
  display: none;
}
.toggleBtnActive {
  span::before,
  span::after,
  span {
    background: red;
  }
  span::before {
    transform: rotate(45deg) !important;
  }
  span::after {
    transform: rotate(-45deg);
  }
  span {
    height: 0;
  }
}

.sidebar {
  top: 120px;
  background: $white;
  width: 375px;
  height: 476px;
  position: absolute;
  left: -500px;
  transition: all 600ms;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.sidebar.active {
  display: flex;
  left: 0;
  z-index: 5;
}

.main-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    padding-left: 16px;
    display: flex;
    align-items: center;
    height: 64px;
    font-size: inRem(16px);
    font-family: Roboto-Regular, sans-serif;
    font-weight: 400;
    color: $black;
    cursor: pointer;
    background-color: inherit;
    text-decoration: none;
  }

  a:hover {
    background-color: $very-light-gray;
  }

  button {
    padding-left: 16px;
    display: flex;
    align-items: center;
    height: 64px;
    font-size: inRem(16px);
    font-family: Roboto-Regular, sans-serif;
    font-weight: 400;
    color: $black;
    background: inherit;
    border: none;
  }

  button:focus {
    outline: none;
  }

  button:hover {
    background-color: $very-light-gray;
  }
}

@media screen and(max-width: 420px) {
  .sidebar {
    width: 420px;
  }
}

@media screen and(max-width: 375px) {
  .sidebar {
    width: 375px;
  }
}

@media screen and(max-width: 360px) {
  .sidebar {
    width: 360px;
  }
}

@media screen and(max-width: 320px) {
  .sidebar {
    width: 320px;
  }
}
</style>
