import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export function defaultState() {
  return {
    bankenProfile: [],
    availableDates: [],
    allReports: [],
    loading: false,
    error: false,
    uploadedReports: false,
    bankDetails: {},
    singleReport: {
      rows: [],
    },
    isLoadingByDate: false,
    snackbarData: {
      show: false,
      text: '',
      timeout: 0,
    },
  };
}
const state = defaultState();

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
