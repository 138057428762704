import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  tabs: [
    {
      text: 'Ansparplan',
      date: '02.06.2021',
      pdf: 'pdfUrl',
    },
    {
      text: 'Zuwachsparen',
      date: '02.06.2021',
      pdf: '',
      xls: 'xlsUrl',
    },
    { text: 'Tagesgeld', date: '02.06.2021', pdf: 'url', xls: 'xlsUrl' },
    { text: 'Girikonto' },
    { text: 'Festgeld' },
    { text: 'Sparbriefe' },
    { text: 'Ratenkredit' },
    { text: 'Spareinlage' },
    { text: 'Hyp' },
    { text: 'Hyp 20' },
    { text: 'Hyp 30' },
  ],
  table: {
    tabs: ['2021', '2020', '2019'],
    headers: [
      { text: 'Datum', value: 'datum' },
      { text: 'PDF', value: 'pdf' },
      { text: 'XLS', value: 'xls' },
    ],
    rows: [{ datum: '2021-12-01', pdf: 'pdfUrl', xls: 'xlsUrl' }],
  },
  loading: false,
  error: false,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
