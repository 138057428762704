import { getField } from 'vuex-map-fields';

export default {
  getField,
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  allReportsBaugeld(state) {
    return state.allReportsBaugeld;
  },
  allReportsFestgeld(state) {
    return state.allReportsFestgeld;
  },
  allReportsTagesgeld(state) {
    return state.allReportsTagesgeld;
  },
  loadingAllReports(state) {
    return state.loadingAllReports;
  },
  singleReport(state) {
    return state.singleReport;
  },
  getFieldValue: (state) => (path) => {
    let paths = path.split('.');
    return state[paths[0]][paths[1]][paths[2]];
  },
  getCopyReport(state) {
    return state.copySingleReport;
  },
};
