import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import wizard from './wizard';
import reports from './reports';
import dateNow from './dateNow';
import baugeld from './baugeld';
import auth from './auth';
import datenabo from './datenabo';
import anbieter from '../../wettbewerbsvergleich/src/store/modules/anbieter';
import chartColors from '../../wettbewerbsvergleich/src/store/modules/chart-colors';
import param from '../../wettbewerbsvergleich/src/store/modules/param';
import result from '../../wettbewerbsvergleich/src/store/modules/result';
import bankingProfile from './bankingProfile';
import interestCharts from './interestCharts';
import specialSubscribers from '../../wettbewerbsvergleich/src/store/modules/special-subscribers/index.js';
import wettbewerb from '../../wettbewerbsvergleich/src/store/modules/wettbewerb/index';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const plugins = debug ? [createLogger({})] : [];

export const store = new Vuex.Store({
  modules: {
    wizard,
    reports,
    dateNow,
    baugeld,
    auth,
    anbieter,
    chartColors,
    param,
    result,
    specialSubscribers,
    datenabo,
    wettbewerb,
    bankingProfile,
    interestCharts,
  },
  actions: {
    resetFestGeld({ commit }) {
      commit('param/resetStateFestGeld');
      commit('result/resetStateFestGeld');
    },
    resetHypotheken({ commit }) {
      commit('param/resetStateHypotheken');
      commit('result/resetStateHypotheken');
    },
    resetStore({ commit }) {
      commit('param/resetState');
      commit('result/resetState');
    },
  },
  plugins,
});
