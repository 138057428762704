import mutations from './mutations';
import getters from './getters';

const state = {
  rowsForBaugeldTables: [],
  editRow: {},
  copyProduct: {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
