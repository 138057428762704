<template>
  <v-btn-toggle
    v-model="modelSource"
    color="selected"
    mandatory
    class="languages-selection-btns"
  >
    <v-btn small value="de">DE</v-btn>
    <v-btn small value="en">EN</v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import i18n from '@/plugins/i18n';

import { BASE_COLOR } from '@/utils/constants';

@Component({})
export default class LanguageSelection extends Vue {
  color = BASE_COLOR;
  model = 'de';

  get modelSource() {
    return this.model;
  }
  set modelSource(val: string) {
    this.model = val;
    localStorage.setItem('language', JSON.stringify(val));
    i18n.locale = val;
  }

  mounted() {
    const language = JSON.parse(localStorage.getItem('language') || '""');
    if (language) {
      this.model = language;
      i18n.locale = language;
    }
  }
}
</script>

<style lang="scss"></style>
