import Vue from 'vue';
import { mapGetters } from 'vuex';

import { SETTINGS } from './utils/constants';

Vue.mixin({
  computed: {
    ...mapGetters({
      getAnbieter: 'anbieter/getAnbieter',
    }),
    providerCount() {
      return (
        (this.getAnbieter?.[1]?.length || 0) +
        (this.getAnbieter?.[2]?.length || 0) +
        (this.getAnbieter?.[4]?.length || 0)
      );
    },
  },
  methods: {
    parsePeriod(formParam, date) {
      const von = formParam.zeitraumVon;
      const bis = formParam.zeitraumBis;
      const zeitraum = formParam.zeitraum;
      if (von && bis && zeitraum === 0) {
        return `${date(von).format('DD.MM.YYYY')} - ${date(bis).format(
          'DD.MM.YYYY'
        )}`;
      }
      return Vue.filter('zeitraumJahre')(zeitraum);
    },
    parseDate: function (str) {
      try {
        const parts = str.match(/(\d+)/g);
        return new Date(parts[2], parts[1] - 1, parts[0]);
      } catch (error) {
        return null;
      }
    },
    formatDate: function (d) {
      return d.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    formatProzent: function (value, digits) {
      return (
        value.toLocaleString('de-DE', { minimumFractionDigits: digits }) +
        ' ' +
        SETTINGS.prozentZeichen
      );
    },
    hexToRGB: function (hex) {
      return `rgb(${+(+`0x${hex[1]}${hex[2]}`)},${+(+`0x${hex[3]}${hex[4]}`)},${+(+`0x${hex[5]}${hex[6]}`)})`;
    },
    hexToRGBA: function (hex, a) {
      const r = `0x${hex[1]}${hex[2]}`;
      const g = `0x${hex[3]}${hex[4]}`;
      const b = `0x${hex[5]}${hex[6]}`;
      return 'rgba(' + +r + ', ' + +g + ', ' + +b + ', ' + a.toFixed(2) + ')';
    },
  },
});
