export default {
  error(state, payload) {
    state.error = payload;
  },
  loading(state, payload) {
    state.loading = payload;
  },
  setDatenaboData(state, payload) {
    state.tabs = payload.tabs;
    state.table = {
      ...state.table,
      ...{
        tabs: payload.table.tabs,
        headers: payload.table.headers || [],
        rows: payload.table.rows || [],
      },
    };
  },
  clearDatenaboData(state) {
    state.tabs = [];
    state.table = {
      tabs: [],
      headers: [],
      rows: [],
    };
  },
};
