export default {
  sections(state) {
    return state.sections;
  },
  error(state) {
    return state.error;
  },
  loading(state) {
    return state.loading;
  },
  maxColumns(state) {
    return state.maxColumns;
  },
};
