export default {
  loading(state, payload) {
    state.loading = payload;
  },
  error(state, payload) {
    state.error = payload;
  },
  setBaseChartData(state, payload) {
    state.baseChartData = payload;
  },
};
