export default {
  setRowsBaugeldTable(state, payload) {
    const { config } = payload;
    const result = config.products.map(
      ({
        product_kaufpreis,
        product_beleihung,
        product_zinsbindung,
        product_tilgung,
        ...rest
      }) => {
        const o = { ...rest };
        o.product_kaufpreis = product_kaufpreis;
        o.product_beleihung = product_beleihung;
        o.product_zinsbindung = product_zinsbindung;
        o.product_tilgung =
          typeof product_tilgung === 'undefined' ? '' : product_tilgung;
        return o;
      }
    );
    state.rowsForBaugeldTables = config.products ? result : [];
  },
  setRowBaugeldTable(state, payload) {
    state.rowsForBaugeldTables = [...state.rowsForBaugeldTables].concat([
      { ...payload },
    ]);
  },
  deleteRowBaugeldTable(state, payload) {
    state.rowsForBaugeldTables = state.rowsForBaugeldTables.filter(
      (item) => item.id !== payload
    );
  },
  editRow(state, payload) {
    state.editRow = { ...payload };
  },
  updateRow(state, payload) {
    state.rowsForBaugeldTables = [...state.rowsForBaugeldTables].map((i) => {
      if (i.id === payload.id) return { ...payload };
      return { ...i };
    });
  },
  clearTableData(state) {
    state.rowsForBaugeldTables = [];
  },
  setCopyProduct(state, payload) {
    state.copyProduct = { ...payload };
  },
};
