import { instance } from '@/utils';

export default {
  fetchDatenaboData({ commit }) {
    instance
      .get('/datenabo')
      .then((res) => {
        commit('setDatenaboData', res.data);
      })
      .catch((e) => {
        commit('loading', false);
        commit('error', true);
        console.log(e.response);
      });
  },
  fetchArchiveData({ commit }, year) {
    instance
      .get(`/datenabo/${year}`)
      .then((res) => {
        commit('setDatenaboData', res.data);
      })
      .catch((e) => {
        commit('loading', false);
        commit('error', true);
        console.log(e.response);
      });
  },
};
