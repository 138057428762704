import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export function defaultState() {
  return {
    sections: [],
    maxColumns: null,
    defaultCountColumns: null,
    error: false,
    loading: false,
  };
}
const state = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
